import React, { useMemo } from 'react';
import { NextComponentType } from 'next';
import { getCatalogIndexItems, catalogSelector } from '@store/ducks/catalog';
import { AppContextWithStore } from './_app';
import { useSelector } from 'react-redux';
import CatalogItem from '@src/components/Catalog/CatalogItem';
import * as styles from '@src/scss/pages/main/styles.scss';
import Categories from '@components/Categories';
import clsx from 'clsx';
import useMobileLayout from '@src/hooks/useMobileLayout';
import CatalogMobileControls from '@components/Catalog/MobileControls';
import { buildBreadcrumbs } from '@ducks/application/breadcrumbs';
import {
    shopSettingsIsLeftMenuHiddenSelector,
    shopSettingsSelector,
} from '@ducks/application/shopSettings';

type CatalogPageProps = unknown;
const IndexPage: NextComponentType<
    AppContextWithStore,
    CatalogPageProps,
    CatalogPageProps
> = () => {
    const CatalogState = useSelector(catalogSelector);
    const isLeftMenuHidden = useSelector(shopSettingsIsLeftMenuHiddenSelector);
    const { catalogDisplayColumns } = useSelector(shopSettingsSelector);
    const classColumsItem = (() => {
        switch (catalogDisplayColumns) {
            case 4:
                return styles.fourColumsItem;
            case 5: {
                return styles.fiveColumsItem;
            }
            default:
                return null;
        }
    })();

    const isMobile = useMobileLayout();

    const CatalogItems = useMemo(() => {
        return CatalogState.items.map((item) => (
            <CatalogItem item={item} key={item.id} />
        ));
    }, [CatalogState.items]);

    if (isMobile) {
        return (
            <>
                <CatalogMobileControls className={styles.mobileFilters} />
                <div
                    className={clsx(
                        styles.catalogItems,
                        isMobile && styles.catalogItemsMobile
                    )}
                >
                    {CatalogItems}
                </div>
            </>
        );
    }

    return (
        <>
            {!isLeftMenuHidden && (
                <div className={clsx(styles.categories, classColumsItem)}>
                    <Categories />
                </div>
            )}
            <div
                className={clsx(
                    styles.catalogItems,
                    isLeftMenuHidden && styles.catalogItems__fullwidth,
                    classColumsItem
                )}
            >
                {CatalogItems}
            </div>
        </>
    );
};

IndexPage.getInitialProps = async (context) => {
    const reduxStore = context.reduxStore;
    reduxStore.dispatch(buildBreadcrumbs({}));
    await reduxStore.dispatch(getCatalogIndexItems());
};

export default IndexPage;
